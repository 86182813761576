import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

function sumValues(obj) {
    if (!obj) return;

    const { name, ...rest } = obj;
    return Object.values(rest).reduce((sum, value) => sum + value, 0);
}

function sumValuesPrev(obj) {
    if (!obj) return;

    const { name, ...rest } = obj;
    return Object.values(rest).reduce((sum, value) => sum + (value - 2), 0);
}

const CustomTooltip = ({ active, payload, withCompare }) => {
    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        const total = sumValues(dataPoint);
        const totalPrev = sumValuesPrev(dataPoint);

        return (
            <div className="custom-tooltip">
                <Stack>
                    <Typography
                        color="#818992"
                        fontSize="14px"
                        fontWeight="bold"
                    >
                        {dataPoint.name.split("_")[0]}
                    </Typography>
                    <Typography
                        color="#06262D"
                        fontSize="12px"
                        fontWeight="bold"
                    >
                        Total Sales : {total}
                    </Typography>
                    {withCompare && (
                        <Typography
                            color="#06262D"
                            fontSize="12px"
                            fontWeight="bold"
                        >
                            Previous Period : {totalPrev}
                        </Typography>
                    )}

                    <Stack>
                        {Object.keys(dataPoint)
                            .filter((d) => d !== "name")
                            .map((dataKey) => (
                                <Stack flexDirection="row" key={dataKey}>
                                    <Typography color="#06262D" fontSize="12px">
                                        {dataKey}: {dataPoint[`${dataKey}`]}
                                    </Typography>{" "}
                                    {withCompare && (
                                        <>
                                            ({"  "}
                                            <Typography
                                                color="#06262D"
                                                fontSize="12px"
                                            >
                                                Previous Period:{" "}
                                                {dataPoint[`${dataKey}`] - 2}
                                            </Typography>
                                            )
                                        </>
                                    )}
                                </Stack>
                            ))}
                    </Stack>
                </Stack>
            </div>
        );
    }
    return null;
};

const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#202020">
            {payload.value}
        </text>
    );
};

const VerticalStackedBarChart = ({ data, withCompare }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                layout="vertical" // For vertical orientation
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke="#EEEFF4" horizontal={false} />
                <XAxis
                    type="number"
                    tickLine={false}
                    stroke="#EEEFF4"
                    tick={<CustomizedXAxisTick />}
                    // domain={[0, 100]}
                    reversed={isArabic}
                />
                <YAxis dataKey="name" type="category" tickLine={false} />
                <Tooltip
                    content={<CustomTooltip withCompare={withCompare} />}
                    wrapperStyle={{ outline: "none" }}
                />
                {/* Stacked bars */}
                <Bar
                    dataKey="Hungerstation"
                    stackId="a"
                    fill="#9FE3B9"
                    barSize={20}
                />
                <Bar dataKey="Ninja" stackId="a" fill="#5BCFDE" barSize={20} />
                <Bar dataKey="Mrsool" stackId="a" fill="#06262D" barSize={20} />
                <Bar dataKey="Jahez" stackId="a" fill="#E3E3E3" barSize={20} />
                <Bar dataKey="noon" stackId="a" fill="#DDFBA9" barSize={20} />
                <Bar
                    dataKey="theChefz"
                    stackId="a"
                    fill="#837BC4"
                    barSize={20}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default VerticalStackedBarChart;
