import { useState } from "react";
import {
    Box,
    Stack,
    ClickAwayListener,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { getUserStorage } from "../../utils";
import { constants } from "../../../config/constants";
import { SideNavItemExtend } from "./SideNavItemExtend";
import { SideNavItem } from "./SideNavItem";

const ExtendArabicLogo = () => (
    <Box display="flex">
        <img
            src="/logo_small.svg"
            width="176px"
            height="31px"
            alt="lgo"
            style={{
                position: "absolute",
                left: 0,
                right: "-42px",
                top: "0",
            }}
        />
        <img
            src="/logo_ballurh_ar.svg"
            width="200px"
            height="40px"
            alt="lgo"
            style={{ position: "relative", top: -6, right: -8 }}
        />
    </Box>
);

const SideNavbar = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    const minimizedWidth = isMobileView ? "75px" : "100px";
    const fullSizeWidth = isMobileView ? "275px" : "300px";

    const user = getUserStorage();
    const isBasicPlan =
        user?.merchant?.subscription === 2 ||
        user?.merchant?.subscription === 4;
    const isOwner = user.role === 1;
    const isGroupOwner = user.role === 9;

    // Like for like
    const hasLikeForLIkeAccess =
        isOwner || user.access.includes("Like-for-like");
    // Basket
    const hasBasketAccess = isOwner || user.access.includes("Bought Together");
    // Sales
    const hasSalesAccess =
        isOwner || isGroupOwner || user.access.includes("Sales");
    const hasSalesInsightsAccess =
        isOwner || isGroupOwner || user.access.includes("Sales Insights");
    const hasSalesPerformanceAccess =
        isOwner || isGroupOwner || user.access.includes("Sales Performance");
    const hasSalesAggregatorsAccess =
        isOwner || isGroupOwner || user.access.includes("Sales Aggregators");
    // Customer
    const hasCustomersAccess = isOwner || user.access.includes("Customers");
    const hasCustomersDataAccess =
        isOwner || user.access.includes("Customers Data");
    const hasCustomersInsightsAccess =
        isOwner || user.access.includes("Customer Insights");
    // Menu
    const hasMenuAccess = isOwner || user.access.includes("Menu");
    const hasMenuEngineeringAccess =
        isOwner || user.access.includes("Menu Engineering");

    const { pathname } = useLocation();

    const [isFullSidebar, setIsFullSidebar] = useState(false);

    const changeLanguage = (lang) => {
        localStorage.setItem(constants.storage.language, lang);
        i18n.changeLanguage(lang);
        window.location.reload();
    };

    const sideNavMenuItems = [
        {
            key: "sales",
            isDisabled: !hasSalesAccess,
            to: hasSalesInsightsAccess ? "/sales/insights" : null,
            label: t("sales.sales"),
            iconPath: pathname.includes("sales")
                ? "/ic_sales_selected.svg"
                : "/ic_sales.svg",
            subItems: [
                {
                    label: t("sales.insights"),
                    to: "/sales/insights",
                    iconPath: "/sales_icon.svg",
                    isDisabled: !hasSalesInsightsAccess,
                },
                {
                    label: t("sales.performance"),
                    to: "/sales/performance",
                    iconPath: "/sales performance.svg",
                    isDisabled: !hasSalesPerformanceAccess,
                },
                {
                    label: t("sales.aggregators"),
                    to: "/sales/aggregators",
                    iconPath: "/sales_aggregators.svg",
                    isDisabled: !hasSalesAggregatorsAccess,
                },
            ],
        },
        {
            key: "customers",
            isDisabled: !hasCustomersAccess,
            label: t("customer.customers"),
            to: hasCustomersInsightsAccess ? "/customers/insights" : null,
            iconPath: pathname.includes("customers")
                ? "/ic_customers selected.svg"
                : "/ic_customers.svg",
            subItems: [
                {
                    label: t("customer.insights"),
                    to: "/customers/insights",
                    iconPath: "/customer_insights.svg",
                    isDisabled: !hasCustomersInsightsAccess,
                },
                // {label: "Customer Performance", to: '/customers/performance'},
                {
                    label: t("customer.data"),
                    to: "/customers/data",
                    iconPath: "/customers_data.svg",
                    isDisabled: !hasCustomersDataAccess,
                },
            ],
        },
        {
            key: "menu",
            isDisabled: isBasicPlan || !hasMenuAccess,
            iconPath: isBasicPlan
                ? "/ic_product_disabled.svg"
                : pathname.includes("menu")
                ? "/ic_products selected.svg"
                : "/ic_product.svg",
            to: hasMenuEngineeringAccess ? "/menu/insights" : "/menu/products",
            label: t("menu.menu_engineering"),
            subItems: [
                {
                    label: t("menu.insights"),
                    to: "/menu/insights",
                    iconPath: "/menu_insights_icon.svg",
                    isDisabled: !hasMenuEngineeringAccess,
                },
                {
                    label: t("menu.prodcuts"),
                    to: "/menu/products",
                    iconPath: "/menu_products.svg",
                },
            ],
        },
        {
            key: "basket",
            isDisabled: isBasicPlan || !hasBasketAccess,
            iconPath: isBasicPlan
                ? "/ic_bought_disabled.svg"
                : pathname.includes("basket")
                ? "/ic_bought_selected.svg"
                : "/ic_bought.svg",
            to: "/basket/freq",
            label: t("basket.bught_together"),
            subItems: [
                {
                    label: t("basket.bught_together"),
                    to: "/basket/freq",
                    iconPath: "/freq_bought.svg",
                },
                {
                    label: t("basket.basket"),
                    to: "/basket/product",
                    iconPath: "/basket_product.svg",
                },
            ],
        },
        {
            key: "likeForLike",
            isDisabled: isBasicPlan || !hasLikeForLIkeAccess,
            iconPath: isBasicPlan
                ? "/ic_like for like_disabled.svg"
                : pathname.includes("likeForLike")
                ? "/ic_like for like_selected.svg"
                : "/ic_like for like.svg",
            to: "/likeForLike/products",
            label: t("likeForLike.likeForLike"),
            subItems: [
                {
                    label: t("likeForLike.branch_like"),
                    to: "/likeForLike/branches",
                    // iconPath: null,
                    iconPath: "/branches_like.svg",
                },
                {
                    label: t("likeForLike.product_like"),
                    to: "/likeForLike/products",
                    iconPath: "/menu_products.svg",
                },
                {
                    label: t("likeForLike.category_like"),
                    to: "/likeForLike/categories",
                    iconPath: "/categories_like.svg",
                },
            ],
        },
        {
            key: "pixel",
            isBeta: true,
            iconPath: pathname.includes("pixel")
                ? "/forcast_history_outline.svg"
                : "/forcast_history_outline.svg",
            to: "/pixel",
            label: t("common.pixel"),
            subItems: [
                {
                    label: t("common.key_events"),
                    to: "/pixel",
                    iconPath: "/forcast_history.svg",
                    isDisabled: false,
                    isBeta: true,
                },
                {
                    label: t("common.offer_desinger"),
                    to: "/pixel",
                    iconPath: "/forcast_overview.svg",
                    isDisabled: true,
                    isSoon: true,
                },
            ],
        },
        {
            key: "forcast",
            isSoon: true,
            iconPath: pathname.includes("forcast")
                ? "/forcast_icon_selected.svg"
                : "/forcast_icon.svg",
            to: "/forcast/overview",
            label: t("settings.forcast"),
            subItems: [
                // {
                //     label: t("settings.forcast_history"),
                //     to: "/forcast/history",
                //     iconPath: "/forcast_history.svg",
                //     isDisabled: true,
                // },
                {
                    label: t("settings.forcast_overview"),
                    to: "/forcast/overview",
                    iconPath: "/forcast_overview.svg",
                    isDisabled: false,
                },
                // {
                //     label: t("settings.forcast_lab"),
                //     to: "/forcast/lab",
                //     iconPath: "/forcast_lab.svg",
                //     isDisabled: true,
                // },
            ],
        },
        {
            key: "assistant",
            isSoon: true,
            iconPath: pathname.includes("assistant")
                ? "/assistant.svg"
                : "/assistant.svg",
            to: "/assistant",
            label: t("settings.assistant"),
        },
    ];

    const ownerNavMenuItems = [
        {
            key: "settings",
            iconPath: pathname.includes("settings")
                ? "/ic_settings_selected.svg"
                : "/ic_settings.svg",
            to: "/settings/branches",
            label: t("settings.settings"),
            subItems: [
                {
                    label: t("settings.my_branches"),
                    to: "/settings/branches",
                    iconPath: "/branches_icon.svg",
                },
                {
                    label: t("settings.my_team"),
                    to: "/settings/team",
                    iconPath: "/my_team.svg",
                },
                {
                    label: t("settings.subscriptions"),
                    to: "/settings/subscription",
                    iconPath: "/ic_subscription.svg",
                },
            ],
        },
    ];

    const isInBallurhAssistant = pathname.includes("assistant");
    const groupOwnerNavMenuItems = [
        {
            key: "settings",
            iconPath: pathname.includes("settings")
                ? "/ic_settings_selected.svg"
                : "/ic_settings.svg",
            to: "/settings/owners",
            label: t("settings.settings"),
            subItems: [
                {
                    label: t("settings.my_team"),
                    to: "/settings/owners",
                    iconPath: "/my_team.svg",
                },
            ],
        },
    ];

    return (
        <ClickAwayListener onClickAway={() => setIsFullSidebar(false)}>
            <Stack
                minHeight={!isInBallurhAssistant && "100vh"}
                maxHeight={isInBallurhAssistant && "100vh"}
                overflow={isInBallurhAssistant && "scroll"}
                minWidth={isFullSidebar ? fullSizeWidth : minimizedWidth}
                top={0}
                bottom={0}
                position="static"
                bgcolor="#06262D"
                zIndex={1000}
                style={{ transition: "min-width .1s ease" }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={
                        isFullSidebar ? (isArabic ? "right" : "left") : "center"
                    }
                    paddingLeft={isFullSidebar && !isArabic && 8}
                    paddingRight={isFullSidebar && isArabic && 8}
                    width="100%"
                    marginTop="34px"
                    position="relative"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsFullSidebar(!isFullSidebar)}
                >
                    {isFullSidebar ? (
                        isArabic ? (
                            <ExtendArabicLogo />
                        ) : (
                            <img
                                src="/logo_extend.svg"
                                width={isFullSidebar ? 176 : "32px"}
                                height="31px"
                                alt="lgo"
                            />
                        )
                    ) : (
                        <img
                            src="/logo_small.svg"
                            width={isFullSidebar ? 176 : "32px"}
                            height="31px"
                            alt="lgo"
                            style={{
                                position: "relative",
                                left: isArabic ? 0 : "-4px",
                                right: isArabic ? "-4px" : 0,
                            }}
                        />
                    )}

                    {isFullSidebar ? (
                        isArabic ? (
                            <ArrowForwardIosIcon
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: 4,
                                    left: 32,
                                }}
                            />
                        ) : (
                            <ArrowBackIosNewIcon
                                sx={{
                                    color: "white",
                                    // fontSize: "14px",
                                    position: "absolute",
                                    top: 8,
                                    right: 32,
                                }}
                            />
                        )
                    ) : isArabic ? (
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "white",
                                fontSize: "14px",
                                position: "absolute",
                                top: 8,
                                left: 8,
                            }}
                        />
                    ) : (
                        <ArrowForwardIosIcon
                            sx={{
                                color: "white",
                                fontSize: "14px",
                                position: "absolute",
                                top: 8,
                                right: 8,
                            }}
                        />
                    )}
                </Box>

                <Stack spacing={3} paddingY={6} pt={20} alignItems="center">
                    {isFullSidebar ? (
                        <SideNavItemExtend
                            isActive={
                                pathname.includes("overview") ||
                                pathname === "/"
                            }
                            iconPath={
                                pathname.includes("overview")
                                    ? "/ic_overview_selected.svg"
                                    : "/ic_overview.svg"
                            }
                            to="overview"
                            label={t("overview.overview")}
                        />
                    ) : (
                        <SideNavItem
                            iconPath={
                                pathname.includes("overview") ||
                                pathname === "/"
                                    ? "/ic_overview_selected.svg"
                                    : "/ic_overview.svg"
                            }
                            to="overview"
                            label={t("overview.overview")}
                        />
                    )}

                    {sideNavMenuItems.map((sideNavItem) => {
                        const SideNavComponent = isFullSidebar
                            ? SideNavItemExtend
                            : SideNavItem;
                        return (
                            <SideNavComponent
                                key={sideNavItem.key}
                                isActive={pathname.includes(sideNavItem.key)}
                                iconPath={sideNavItem.iconPath}
                                to={sideNavItem.to}
                                label={sideNavItem.label}
                                subItems={sideNavItem.subItems}
                                isDisabled={sideNavItem.isDisabled}
                                isSoon={sideNavItem.isSoon}
                                isBeta={sideNavItem.isBeta}
                            />
                        );
                    })}

                    {/* {isFullSidebar ? (
                        <SideNavItemExtend
                            iconPath="/forcast_history_outline.svg"
                            to="pixel"
                            label="Pixel"
                            isBeta
                        />
                    ) : (
                        <SideNavItem
                            iconPath="/forcast_history_outline.svg"
                            label={t("overview.overview")}
                            to="pixel"
                            isBeta
                        />
                    )} */}
                    {isOwner &&
                        ownerNavMenuItems.map((ownerSideNavItem) => {
                            const SideNavComponent = isFullSidebar
                                ? SideNavItemExtend
                                : SideNavItem;
                            return (
                                <SideNavComponent
                                    isActive={pathname.includes(
                                        ownerSideNavItem.key
                                    )}
                                    iconPath={ownerSideNavItem.iconPath}
                                    to={ownerSideNavItem.to}
                                    label={ownerSideNavItem.label}
                                    subItems={ownerSideNavItem.subItems}
                                />
                            );
                        })}

                    {isGroupOwner &&
                        groupOwnerNavMenuItems.map((ownerSideNavItem) => {
                            const SideNavComponent = isFullSidebar
                                ? SideNavItemExtend
                                : SideNavItem;
                            return (
                                <SideNavComponent
                                    isActive={pathname.includes(
                                        ownerSideNavItem.key
                                    )}
                                    iconPath={ownerSideNavItem.iconPath}
                                    to={ownerSideNavItem.to}
                                    label={ownerSideNavItem.label}
                                    subItems={ownerSideNavItem.subItems}
                                />
                            );
                        })}

                    {
                        <Box paddingTop={10}>
                            {isFullSidebar ? (
                                <SideNavItemExtend
                                    iconPath="/ic_language.svg"
                                    label={t("common.language")}
                                    subItems={[
                                        {
                                            label: t("common.english"),
                                            onClick: () => changeLanguage("en"),
                                            iconPath: "/english.svg",
                                        },
                                        {
                                            label: t("common.arabic"),
                                            onClick: () => changeLanguage("ar"),
                                            iconPath: "/arabic.svg",
                                        },
                                    ]}
                                />
                            ) : (
                                <SideNavItem
                                    iconPath="/ic_language.svg"
                                    label={t("common.language")}
                                    subItems={[
                                        {
                                            label: t("common.english"),
                                            onClick: () => changeLanguage("en"),
                                            iconPath: "/english.svg",
                                        },
                                        {
                                            label: t("common.arabic"),
                                            onClick: () => changeLanguage("ar"),
                                            iconPath: "/arabic.svg",
                                        },
                                    ]}
                                />
                            )}
                        </Box>
                    }
                </Stack>
            </Stack>
        </ClickAwayListener>
    );
};

export { SideNavbar };
