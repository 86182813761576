import {
    Box,
    Divider,
    Stack,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import {
    CustomSelect,
    CustomSelectOutline,
    GridCardFlatLabels,
} from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import VerticalStackedBarChart from "./StackedVerticalChart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import StackedBarChart from "./StackedBarChart";

function reshuffleValues(data) {
    return data.map((item) => {
        const { name, ...rest } = item; // Extract the name key and values (without name)
        const values = Object.values(rest); // Get all values
        const keys = Object.keys(rest); // Get all keys

        // Fisher-Yates shuffle for values
        for (let i = values.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [values[i], values[j]] = [values[j], values[i]]; // Swap values
        }

        // Rebuild the object with the shuffled values
        const shuffledItem = { name };
        keys.forEach((key, index) => {
            shuffledItem[key] = values[index]; // Assign shuffled values to the corresponding keys
        });

        return shuffledItem; // Return the reshuffled object
    });
}
// Fake data with stacked values
const fakeData = [
    {
        name: "8/18",
        Hungerstation: 40,
        Ninja: 30,
        Mrsool: 20,
        Jahez: 15,
        noon: 10,
        theChefz: 5,
    },
    {
        name: "8/20",
        Hungerstation: 50,
        Ninja: 25,
        Mrsool: 15,
        Jahez: 20,
        noon: 25,
        theChefz: 10,
    },
    {
        name: "8/22",
        Hungerstation: 60,
        Ninja: 35,
        Mrsool: 25,
        Jahez: 10,
        noon: 20,
        theChefz: 15,
    },
    {
        name: "8/24",
        Hungerstation: 70,
        Ninja: 40,
        Mrsool: 30,
        Jahez: 25,
        noon: 15,
        theChefz: 20,
    },
    {
        name: "8/26",
        Hungerstation: 65,
        Ninja: 30,
        Mrsool: 20,
        Jahez: 35,
        noon: 25,
        theChefz: 15,
    },
    {
        name: "8/28",
        Hungerstation: 15,
        Ninja: 35,
        Mrsool: 10,
        Jahez: 25,
        noon: 28,
        theChefz: 25,
    },
    {
        name: "8/30",
        Hungerstation: 25,
        Ninja: 15,
        Mrsool: 28,
        Jahez: 19,
        noon: 4,
        theChefz: 11,
    },
    {
        name: "9/1",
        Hungerstation: 15,
        Ninja: 9,
        Mrsool: 11,
        Jahez: 29,
        noon: 44,
        theChefz: 21,
    },
    {
        name: "9/3",
        Hungerstation: 55,
        Ninja: 19,
        Mrsool: 21,
        Jahez: 13,
        noon: 26,
        theChefz: 19,
    },
    {
        name: "9/5",
        Hungerstation: 25,
        Ninja: 29,
        Mrsool: 39,
        Jahez: 12,
        noon: 16,
        theChefz: 49,
    },
    {
        name: "9/7",
        Hungerstation: 15,
        Ninja: 59,
        Mrsool: 29,
        Jahez: 19,
        noon: 26,
        theChefz: 19,
    },
];

const MultiMeetricChartByDate = ({ data = fakeData, isCompare, isPDF }) => {
    const { t } = useTranslation();

    const [chartData, setChartData] = useState(fakeData);

    const labelsData = useMemo(
        () =>
            Object.keys(fakeData[0])
                .filter((k) => k !== "name")
                .map((labelName, index) => ({
                    name: labelName,
                    fill: constants.pieColors[index],
                })),
        []
    );

    const [meetricIndicator, setMeetricIndicator] = useState("total_sales");

    const handleMeetricsUpdate = (e) => {
        setMeetricIndicator(e.target.value);

        setChartData(reshuffleValues(chartData));
    };

    const perc = useMemo(
        () => Math.floor(Math.random() * 30) + 1,
        [meetricIndicator]
    );

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Stack gap={4} flexDirection="row" alignItems="center">
                    <Typography fontWeight="bold" fontSize={20} color="#202020">
                        {t(`overview.${meetricIndicator}`)}
                    </Typography>

                    <Stack gap={1} flexDirection="row">
                        <Box
                            backgroundColor="#f2f2f2"
                            padding={1}
                            borderRadius="50%"
                            width="26px"
                            height="26px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ArrowUpwardIcon sx={{ fontSize: 18 }} />
                        </Box>
                        <Box paddingY={1} color={"green"}>
                            {perc}%
                        </Box>
                    </Stack>
                </Stack>

                {!isPDF && (
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={meetricIndicator}
                        onChange={(e) => handleMeetricsUpdate(e)}
                        input={<CustomSelectOutline />}
                    >
                        <MenuItem value="total_sales">
                            {t("overview.total_sales")}
                        </MenuItem>
                        <MenuItem value="avg_basket_size">
                            {t("overview.avg_basket_size")}
                        </MenuItem>
                        <MenuItem value="total_orders">
                            {t("overview.total_orders")}
                        </MenuItem>
                    </Select>
                )}
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels
                    data={labelsData}
                    isFlat
                    withPercentage={false}
                />

                <Box width="100%" height="300px" px={6}>
                    <StackedBarChart data={chartData} withCompare={isCompare} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { MultiMeetricChartByDate };
